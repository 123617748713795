<template>
  <!-- <div v-if="loading == false && hasPCXProAlert == false">
    <transition name="fade">
      <div>
        <b-card>
          Nesta página você pode consultar seu estoque virtual e enviar item(ns) para seus pacientes.
        </b-card>

        <b-card
          class="mb-2 p-1"
          no-body
        >
          <b-card-header>
            <b-card-title class="text-gray-800">
              Selecione o programa desejado:
            </b-card-title>
          </b-card-header>
          <b-card-body v-if="loading">
            <b-skeleton-img
              no-aspect
              height="250px"
              width="182px"
            />
          </b-card-body>
          <b-card-body v-if="programs.length === 0 && loading == false">
            <p>O estoque virtual ainda não está liberado para você.</p>
          </b-card-body>

          <b-card-body
            class="d-flex flex-wrap pb-0"
          >
            <div
              v-for="program in programs"
              :key="program.id"
              class="rectangle p-2"
              :title="program.name"
              @click="select(program)"
            >
              <div class="box-image d-flex flex-wrap m-auto align-items-center">
                <b-img :src="program.logo_file_path" />
              </div>
              <p class="mt-2">
                {{ program.name }}
              </p>
            </div>
          </b-card-body>
        </b-card>
        <stock-help />
        <stock-CSAT-modal />
      </div>
    </transition>
  </div> -->
  <div>
    <transition name="fade">
      <b-skeleton-img v-if="!pcxProLink" />
    </transition>
    <transition name="fade">
      <b-card
        v-if="pcxProLink"
        class="card-custom"
      >
        <b-card-body
          class="d-flex flex-wrap "
        >
          <b-img
            v-if="!sm"
            src="@/assets/images/pages/pcx-bg.svg"
            fluid
            class="svg-background"
            alt="Imagem SVG"
          />
          <b-img
            v-else
            src="@/assets/images/pages/pcx-bg-mobile.svg"
            fluid
            class="svg-background"
            alt="Imagem SVG"
          />
          <PCXProCard :pcx-pro-link="pcxProLink" />
        </b-card-body>
      </b-card>
    </transition>
  </div>
</template>

<script>
import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core'
import {
  BCard, BCardBody, BImg, BSkeletonImg,
} from 'bootstrap-vue'
// import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import StockHelp from './Stock-help.vue'
// import StockCSATModal from './StockCSATModal.vue'
import PCXProCard from './pcx-pro/PcxProCard.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BImg,
    // BCardHeader,
    // BCardTitle,
    // StockHelp,
    // StockCSATModal,
    BSkeletonImg,
    PCXProCard,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  // directives: {
  //   'b-modal': VBModal,
  //   Ripple,
  // },
  setup() {
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const sm = breakpoints.smallerOrEqual('sm')
    return {
      sm,
    }
  },
  data() {
    return {
      programs: [],
      loading: true,
      hasPCXProAlert: false,
      pcxProLink: '',
    }
  },

  // computed: {
  //   showHelp() {
  //     return this.$store.state.breadcrumb.showHelp
  //   },
  // },

  // watch: {
  //   showHelp(value) {
  //     if (value === true) {
  //       this.$bvModal.show('modal-help')
  //     }
  //   },
  // },
  mounted() {
    this.getPCXProLink()
    // this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
    // this.$store.commit('survey/UPDATE_PCX_CSAT', Date.now())
    // await this.listProgram()
  },

  methods: {
    // select(program) {
    //   this.$store.commit('stock/UPDATE_PROGRAM', program)
    //   this.$router.push('stock-medication')
    // },
    // async listProgram() {
    //   this.loading = true
    //   this.$store.dispatch('stock/listProgram')
    //     .then(async response => {
    //       this.programs = response.data.data.programs_info
    //       if (this.programs.length === 1) {
    //         this.select(this.programs[0])
    //         this.loading = false
    //       }
    //     })
    //     .catch(response => {
    //       this.loading = false
    //       if (response.response.data.error.code !== 200014) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Ops... Algo deu errado, tente novamente',
    //             icon: 'AlertTriangleIcon',
    //             variant: 'danger',
    //           },
    //         })
    //       }
    //     })
    // },
    getPCXProLink() {
      this.$store.dispatch('login/getAuthCode')
        .then(response => {
          if (response.data?.data?.auth_code_info?.code) {
            this.pcxProLink = `${process.env.VUE_APP_PCX_PRO_URL}auth/${response.data.data.auth_code_info.code}`
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

}
</script>

<style lang="scss" scoped>
  .rectangle {
    text-align: center;
    width: 13rem;
    margin-bottom: 2rem;
    min-height: 250px;
    box-shadow: 0px 0px 1em rgb(179, 176, 176);
    cursor: pointer;
    margin-right: 2rem;
    transition: all 0.2s;

    &:hover {
      box-shadow: 0px 0px 1.2em rgb(179, 176, 176);
      transform: scale(1.05);
      position: relative;
      z-index: 1;
    }

    .box-image {
      max-width: 100px;
      min-height: 100px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .card-custom {
    border-radius: 16px;
  }
  .svg-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
</style>
